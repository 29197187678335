import React, {useState} from 'react';
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const msMaxWait = 2000;

const PayPage = () => {
	const [timerBdob, setTimerBdob] = useState(0);
	const [counterBdob, setCounterBdob] = useState(0);
	const [bdobNumber, setBdobNumber] = useState('clique aqui 3 vezes');

	const handleBdobClick = e => {
		e.preventDefault();
		clearTimeout(timerBdob);
		setCounterBdob(prevCount => prevCount + 1);
		if(counterBdob < 2){
			setTimerBdob(
				() => setTimeout( () => clearTimeout(timerBdob), msMaxWait )
			);

		}else{
			setCounterBdob(() => 0);
			setBdobNumber(() => process.env.GATSBY_BDOB_NUMBER);
			navigator.clipboard.writeText(process.env.GATSBY_BDOB_NUMBER).then(
				() => setBdobNumber( () => `tá no clipboard agora: ${process.env.GATSBY_BDOB_NUMBER}` )
			);
		}
		return false;
	};

	return (
		<Layout>
			<Seo title="Payments" />
			<h2>Payments</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/rebecca2.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>
			<h3>Want to send money to Jesse Rivest?</h3>
			<p>
				There are several ways to send me money; please read on to find the best option for you...
				and huge thanks for contributing!
			</p>
			<p className="card">
				<span className="card">
					Would you be interested in receiving news from me?
					Click here afterward: <Link to="/support/#mailinglist">Get news</Link>
				</span>
			</p>

			<h3>Brazil/Brasil</h3>
			<p>
				Eu aceito contribuições por Pix;
				clique três vezes rapidamente pra revelar o chave da conta: <a
				href="/"
				title="account number"
				onKeyDown={handleBdobClick}
				onClick={handleBdobClick}
				>{bdobNumber}</a>
			</p>

			<h3>Canada</h3>
			<p>
				If you're in Canada, I accept Interac e-transfers via <a
				href="mailto:jesse@jesserivest.com"
				>jesse@jesserivest.com</a>.
			</p>

			<h3>Paypal</h3>
			<p>
				A fast and easy way to send me money is via Paypal: <a
				href="//paypal.me/jesserivestmusic"
				title="Paypal"
				>https://paypal.me/jesserivestmusic</a>
			</p>

			<h3>Merch</h3>
			<p>
				If you're just looking to support me with some cash, then why not get some merch in exchange?
				Head to my <Link to="/merch/">merch page</Link> and see what I've got;
				you can even go to Bandcamp and download an album and name your price.
			</p>

			<h3>Crypto!?</h3>
			<p>
				Oh yeah. Web 3.0. I'm totally into it. Not really, but I will put my little toe in and test it out.
				If you're using Brave Browser, you'll see that I accept BAT.
			</p>

		</Layout>
	);
}

export default PayPage;
